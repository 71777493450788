<template>
    <div>
        <DdexAssetModal
            :value="infoModalVisible"
            :data="infoModalData"
            @close="infoModalVisible = false"
        />
        <b-container fluid class="mt-2">
            <b-table
                selectable
                selectedVariant="secondary"
                select-mode="multi"
                @row-selected="rowSelected"
                :items="items"
                :fields="fields"
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
                ref="table"
            >
                <template #cell(info)="data">
                    <b-button @click="showInfo(data.item)" variant="info" size="sm">Info</b-button>
                </template>
            </b-table>

            <!-- Pagination -->
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="my-table"
            ></b-pagination>
            <SimpleModal
                    :value="deleteModalVisible"
                    @ok="doRemove"
                    @cancel="deleteModalVisible = false"
                    msg="Are you sure you want to delete the selected DDEX message?"
            />
            <SimpleModal
                    :value="flagModalVisible"
                    @ok="doFlag"
                    @cancel="flagModalVisible = false"
                    msg="Are you sure you want to flag the selected DDEX message?"
            />
            <SimpleModal
                :value="unflagModalVisible"
                @ok="doUnflag"
                @cancel="unflagModalVisible = false"
                msg="Are you sure you want to unflag the selected DDEX message?"
            />
        </b-container>
        <div v-if="isLoading" class="overlay">
            <div>
                <b-progress
                        :value="100"
                        :max="100"
                        animated
                        style="width: 80%; height: 30px; margin-bottom: 1rem;"
                ></b-progress>
                <div class="text-light">Processing, please wait...</div>
            </div>
        </div>
    </div>
</template>

<script>
import SimpleModal from "./SimpleModal.vue"
import config from "@/config";
import { mapState, mapActions } from 'vuex'
import DdexAssetModal from "./DdexAssetModal.vue";


export default {
    name: 'DdexImport',

    components: {
        SimpleModal,
        DdexAssetModal
    },

    data: function () {
        return {
            items: [],
            isLoading: false,
            fields: ["artist", "friendly_name", "release_type", "number_of_tracks", "release_date", "duration",
                "on_behalf_of", "info"],
            filter: null,
            statusFilter: 'Available',
            selectedItems: [],
            deleteModalVisible: false,
            flagModalVisible: false,
            unflagModalVisible: false,
            infoModalVisible: false,
            infoModalData: null,
            perPage: 200,
            currentPage: 1,
            totalRows: 0,
        };
    },

    props: {},

    methods: {
        ...mapActions('partner', ['fetchPartners']),

        async fetch_ddex(url, options = {}) {
            const hostname = config.ddexUrl;
            const ddexToken = document.cookie;

            const fullUrl = hostname + url;
            const modifiedOptions = {
                ...options,
                headers: {
                    ...options.headers,
                    'x-ddex-tokens': ddexToken,
                },
            };

            return fetch(fullUrl, modifiedOptions);
        },

        async load() {
            let cur_filter = this.statusFilter;
            this.fetch_ddex('/ddex-api/todos?status=' + this.statusFilter)
                .then(function (resp) {
                    return resp.json();
                })
                .then(resp => {
                    this.items = resp.data;
                    this.totalRows = resp.data.length;
                    this.$root.app.setActivePageTitle("DDEX | "+ cur_filter +" (" + this.items.length + ")");
                });
        },

        filterItem(item) {
            this.$log.info("filterItem: " + item.name)
            if (!this.filter) return true

            if (item?.artist && item?.path?.match(this?.filter)) return true

            return false
        },

        setFilter(filter) {
            if (filter) {
                this.filter = new RegExp(filter, "gi");
            } else {
                this.filter = null;
            }

            this.applyFilter();
        },

        applyFilter() {
            // Apply the filter to the items and update the totalRows
            if (this.filter) {
                const filteredItems = this.items.filter(item => this.filterItem(item));
                this.totalRows = filteredItems.length;
            } else {
                this.totalRows = this.items.length;
            }
            this.currentPage = 1;
        },

        setStatusFilter(status) {
            this.statusFilter = status
            this.load()
        },

        remove() {
            if (this.selectedItems.length > 0) {
                this.deleteModalVisible = true
            }
        },

        setFlag() {
            if (this.selectedItems.length > 0) {
                this.flagModalVisible = true
            }
        },
        setUnflag() {
            if (this.selectedItems.length > 0) {
                this.unflagModalVisible = true
            }
        },
        doUnflag() {
            this.doFlag(true)
        },
        doFlag(unflag=false) {
            this.flagModalVisible = false
            const data = this.selectedItems.map(a => {
                return {id: a.id}
            })
            let url = '/ddex-api/set-flagged-list'
            if (unflag) {
                url = '/ddex-api/set-unflagged-list'
            }
            this.fetch_ddex(
                url,
                {
                    method: 'POST',
                    body: JSON.stringify({data: data}),
                    headers: {
                        "Content-Type": "application/json",
                    }
                }
            )
            .then(() => {
                this.$root.app.showAlert("DDEX Item successfully flagged", "success")
                this.load()
            })
        },


        doRemove() {
            this.deleteModalVisible = false
            const data = this.selectedItems.map(a => {
                return {id: a.id}
            })
            this.fetch_ddex(
                `/ddex-api/set-archived-list`,
                {
                    method: 'POST',
                    body: JSON.stringify({data: data}),
                    headers: {
                        "Content-Type": "application/json",
                    }
                }
            )
                .then(() => {
                    this.$root.app.showAlert("DDEX Item successfully archived", "success")
                    this.load()
                })
        },

        rowSelected(items) {
            this.$log.info(items)
            this.selectedItems = items;
        },

        async getPartnersList() {
            try {
                const partnerMap = await this.$store.dispatch('partner/fetchPartners');
                const partnersList = Object.values(partnerMap);
                return partnersList;
            } catch (error) {
                console.error('Error fetching partners:', error);
                return [];
            }
        },

        async fetchItemProperties(item) {
            const response = await this.fetch_ddex(`/ddex-api/get-todo/${item.id}`);
            const data = await response.json();
            let no_rev_share = false;
            let partner_name = data.header.SentOnBehalfOf;
            let partner_id = null;
            if (partner_name === null) {
                partner_name = data.header.MessageRecipient;
            }
            if (partner_name != null) {
                // Get the partner ID:
                const partnersList = await this.getPartnersList();
                const partner = partnersList.find(p => p.name === partner_name);
                if (partner) {
                    partner_id = partner.id;
                }

                if (partner_name === 'IIP-DDS') {
                  no_rev_share = true;
                }

            }
            let release = data.releases.Releases[0]
            let from_date = data.deals.Summary['StartDate'];
            if (from_date != null && from_date !== '') {
                // If there is no time, add it:
                if (from_date.indexOf('T') === -1) {
                    from_date = from_date + 'T05:00:00Z';
                }
                // Otherwise we just keep the date as is
            } else {
                from_date = null;
            }

            let release_data = null;
            let audio_type = 'audio';
            let genres = []
            if (release.Genres) {
              genres = release.Genres;
            }
            if ((release.ReleaseType === 'Album') || (release.ReleaseType === 'EP') ) {
                console.log("FOUND AN ALBUM")
                audio_type = 'album_track';
                release_data = this.generateMediaItem({
                    data: release,
                    partner_id: partner_id,
                    audio_type: audio_type,
                    isrc: null,
                    artist: null,
                    artists: release.Artists,
                    year: parseInt(release.Year),
                    extra: {type: "music_album"},
                    schedule_from: from_date,
                    genres: genres,
                    no_rev_share: no_rev_share
                });
            }

            let recordings = data.resources.SoundRecordings;
            let resulting_recordings = [];
            for (let i = 0; i < recordings.length; i++) {
                let recording = recordings[i];
                let new_recording = this.generateMediaItem({
                    data: recording,
                    partner_id: partner_id,
                    audio_type: audio_type,
                    isrc: recording.ISRC,
                    artist: recording.DisplayArtists[0].FullName,
                    artists: recording.Artists,
                    year: parseInt(recording.Year),
                    extra: {ddex_resource_reference: recording.ResourceReference},
                    schedule_from: from_date,
                    genres: genres,
                    no_rev_share: no_rev_share
                });
                resulting_recordings.push(new_recording)
            }

            let result = {
                release: release_data,
                recordings: resulting_recordings,
                originalData: data
            };
            console.log(result);
            return result;
        },

        generateMediaItem(options) {
            const item = {
                id: 'unknown',
                upc: options.data.ProprietaryId,
                isrc: options.isrc ? [options.isrc] : [],
                parent: null,
                title: options.data.ReferenceTitle,
                type: options.audio_type,
                serial_nr: null,
                partner_id: options.partner_id,
                artist: options.artist,
                artists: options.artists,
                genres: options.genres,
                no_rev_share: options.no_rev_share,
                premium_value: options.audio_type === 'album_track' ? 0 : 100,
                default_language: config.defaultLanguage,
                asset_source: "internal",
                labels: [],
                plan: "premium",
                quality_stamp: false,
                schedule_from: options.schedule_from,
                schedule_to: null,
                geo_whitelist: ['world'],
                geo_blacklist: [],
                category_type: "dynamic",
                link_to: "",
                year: options.year,
                recently_added_exclude: false,
                call_to_action_enabled: false,
                shopping_enabled: false,
                show_meta_text: true,
                cover_enabled: true,
                slide_enabled: true,
                allow_archiving: true,
                is_livestream: false,
                propertyUpdate: true,
                ...options.extra,
            };
            return item;
        },

        async dispatchStoreDdexItem(itemProperties) {
            try {
                let id = await this.$store.dispatch('media/storeDdexItem', itemProperties);
                return id;
            } catch (error) {
                return false;
            }
        },

        ddexAddItem() {
            let items = this.selectedItems;
            console.log(items)
            if (items.length > 20) {
                this.$root.app.showAlert("Too many items selected, please select up to 20 items", "warning")
                return false;
            }
            let successCount = 0;
            let errorCount = 0;
            this.isLoading = true;
            const promises = items.map(async item => {
                let internal_process_entities = {
                    'album': {},
                    'recordings': []
                }
                const itemProperties = await this.fetchItemProperties(item);
                let album_id = null;
                if (itemProperties.release != null) {
                    album_id = await this.dispatchStoreDdexItem.call(this, itemProperties.release);
                    internal_process_entities.album = {'id': album_id}
                }
                for (let i = 0; i < itemProperties.recordings.length; i++) {
                    let recording = itemProperties.recordings[i];
                    recording.parent = album_id;
                    recording.serial_nr = i + 1;
                    let item_id = await this.dispatchStoreDdexItem.call(this, recording);
                    let recording_elem = {
                        'internal_id': recording.ddex_resource_reference,
                        'id': item_id
                    }
                    internal_process_entities.recordings.push(recording_elem)
                }
                console.log('Internal process entities')

                let response = await this.fetch_ddex(`/ddex-api/handle-todo/${item.id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(internal_process_entities)
                });

                let result = await response.json();
                let success = result.success;

                if (success) {
                    successCount++;
                } else {
                    errorCount++;
                }
            });

            Promise.all(promises)
                .then(() => {
                    console.log(successCount);
                    console.log(errorCount);
                    let message = "All items created successfully";
                    this.isLoading = false;
                    this.$root.app.showAlert(message, 'success');
                    this.load();
                })
                .catch(error => this.$root.app.showAlert(error.text, 'danger'));

        },
        async showInfo(item) {
            const itemProperties = await this.fetchItemProperties(item);

            // Display the itemProperties in the info modal
            this.infoModalData = itemProperties;
            this.infoModalVisible = true;
        },

    },

    created() {
        this.fetchPartners()
        this.$root.app.setActivePageTitle("DDEX | Todo")
        this.$root.$emit('set-toolbar', 'ddex-toolbar', 'asset')

        this.load()

        this.$root.$on('ddex-refresh', this.load)
        this.$root.$on('ddex-filter', this.setFilter)
        this.$root.$on('ddex-status-filter', this.setStatusFilter)
        this.$root.$on('ddex-remove', this.remove)
        this.$root.$on('ddex-flag', this.setFlag)
        this.$root.$on('ddex-unflag', this.setUnflag)
        this.$root.$on('ddex-import', this.ddexAddItem)

    },

    computed: {
        ...mapState('partner', ['values']),
        partners() {
            return Object.values(this.values)
        }
    },

    beforeDestroy() {
        this.$root.$off('ddex-refresh', this.load)
        this.$root.$off('ddex-filter', this.setFilter)
        this.$root.$off('ddex-remove', this.remove)
        this.$root.$off('ddex-flag', this.setFlag)
        this.$root.$off('ddex-unflag', this.setUnflag)
        this.$root.$off('ddex-import', this.ddexAddItem)
    }
}
</script>

<style scoped>
.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10;
}
</style>
