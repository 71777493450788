<template>
  <div>
    <b-row>
      <b-col>
        <b-form class="category-edit-form shadow-sm my-2">

          <b-form-group label="Category Labels" label-for="labels-comp" horizontal :label-cols="2">
            <LabelsInput id="labels-comp" :language="language" :labels="categoryFilterLabels" @update="saveEditLabels" :allowNew="false"/>
          </b-form-group>

          <b-form-group label="Category Artists" label-for="artists-comp" horizontal :label-cols="2">
            <LabelsInput id="artists-comp" :language="language" :labels="categoryFilterArtists" @update="saveEditArtists" :allowNew="false" label-type="artist"/>
          </b-form-group>

          <b-form-group label="Category Genres" label-for="genres-comp" horizontal :label-cols="2">
            <LabelsInput id="genres-comp" :language="language" :labels="categoryFilterGenres" @update="saveEditGenres" :allowNew="false" label-type="genre"/>
          </b-form-group>

          <b-form-group label="Content Type" horizontal :label-cols="2">
            <b-form-checkbox-group v-model="categoryFilterType" stacked>
              <b-form-checkbox value=""><em>Select all</em></b-form-checkbox>
              <template v-if="type == 'playlist_page' && assetFormat == 'audio'">
                <b-form-checkbox value="album_track">Album Track</b-form-checkbox>
                <b-form-checkbox value="audio">Audio</b-form-checkbox>
                <b-form-checkbox value="podcast_episode">Podcast Episode</b-form-checkbox>
              </template>
              <template v-else-if="type == 'playlist_page' && assetFormat == 'video'">
                <b-form-checkbox value="episode">Episode</b-form-checkbox>
                <b-form-checkbox value="video">Video</b-form-checkbox>
              </template>
              <template v-else>
                <b-form-checkbox value="artist_page">Artist Page</b-form-checkbox>
                <b-form-checkbox value="audio">Audio</b-form-checkbox>
                <b-form-checkbox value="book">Book</b-form-checkbox>
                <b-form-checkbox value="brand">Brand</b-form-checkbox>
                <b-form-checkbox value="external">External</b-form-checkbox>
                <b-form-checkbox value="magazine">Magazine</b-form-checkbox>
                <b-form-checkbox value="music_album">Music Album</b-form-checkbox>
                <b-form-checkbox value="page">Micro Page</b-form-checkbox>
                <b-form-checkbox value="photo">Photo</b-form-checkbox>
                <b-form-checkbox value="playlist_page">Playlist Page</b-form-checkbox>
                <b-form-checkbox value="podcast">Podcast</b-form-checkbox>
                <b-form-checkbox value="series">Series</b-form-checkbox>
                <b-form-checkbox value="video">Video</b-form-checkbox>
            </template>
            </b-form-checkbox-group>
          </b-form-group>

          <b-form-group label="Plan" horizontal :label-cols="2">
            <b-form-checkbox-group v-model="categoryFilterPlan" v-for="option in $store.state.media.options.plan" :key="option.value">
              <b-form-checkbox :value="option.value">{{ option.text }}</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </b-form>
      </b-col>
      <b-col>
        <b-form v-if="format != 'category'" class="category-edit-form shadow-sm my-2">
          <b-form-group label="Rotation Interval (in days)" horizontal :label-cols="2">
            <b-form-input type="number" v-model="categoryFilterRotateInterval"></b-form-input>
          </b-form-group>
          <b-form-group label="Rotation Amount (in positions)" horizontal :label-cols="2">
            <b-form-input type="number" v-model="categoryFilterRotateSteps"></b-form-input>
          </b-form-group>
        </b-form>


        <div class="d-flex justify-content-start align-items-center mb-2">
          <h5 class="text-weight-bold">Filters</h5>
        </div>

        <b-form-group label="Livestream" label-for="category-filters-livestream" horizontal :label-cols="2">
          <b-form-checkbox id="category-filters-livestream" v-model="categoryFiltersLivestream" switch/>
        </b-form-group>

        <b-form-group label="Quality Stamp" label-for="category-filters-quality-stamp" horizontal :label-cols="2">
          <b-form-checkbox id="category-filters-quality-stamp" v-model="categoryFiltersQualityStamp" switch/>
        </b-form-group>

        <b-form-group label="Recently Added" label-for="category-filters-recently-added" horizontal :label-cols="2">
          <b-form-checkbox id="category-filters-recently-added" v-model="categoryFiltersRecentlyAdded" switch/>
        </b-form-group>

        <b-form-group label="Shopping Enabled" label-for="category-filters-shopping-enabled" horizontal :label-cols="2">
          <b-form-checkbox id="category-filters-shopping-enabled" v-model="categoryFiltersShoppingEnabled" switch/>
        </b-form-group>

      </b-col>
    </b-row>
    <b-form-group v-if="format != 'category'" horizontal :label-cols="2">
      <b-form inline class="d-flex flex-row-reverse">
        <b-form-select :disabled="this.mediaEdit.category_filter.options.includes('recently_added')" id="sort-direction-select" v-model="categoryFilterSortDirection" :options="sortDirectionOptions"/>
        <b-form-select :disabled="this.mediaEdit.category_filter.options.includes('recently_added')" id="sort-type-select" v-model="categoryFilterSortType" :options="sortTypeOptions"/>
      </b-form>
    </b-form-group>
    <b-table v-if="format != 'category'" hover :items="items" :fields="fields">
      <template v-slot:cell(position)="row">
        <div class="d-flex justify-content-end" style="max-width: 80px">
          <span class="mr-2">{{row.index + 1}}.</span>
          <b-button :id="'cat-actions-' + row.item.id" :variant="row.item.isFixed ? 'warning' : 'secondary'" size="sm" @click="handleFixedPosition(row.item)">
            <font-awesome-icon :icon="row.item.isFixed ? 'lock' : 'lock-open'" size="sm"/>
          </b-button>
        </div>
        <b-popover :target="'cat-actions-' + row.item.id" :show.sync="row.item._actionsVisible" placement="left">
          <b-input-group>
            <b-input type="number" placeholder="Set position" :value="getFixedPosition(row.item)" @update="setCurrentIndex($event, row.item)"></b-input>
            <b-input-group-append>
              <b-button size="sm" variant="primary" @click="updateFixedPosition(row.item)">
                Apply
              </b-button>
            </b-input-group-append>
            <b-input-group-append v-if="row.item.isFixed">
              <b-button size="sm" @click="unsetFixedPosition(row.item)">
                Unlock
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-popover>
      </template>
      <template v-slot:cell(publish_status)="row">
        <span :class="publishClass(row.value)" class="rounded">{{row.value}}</span>
      </template>
    </b-table>

  </div>
</template>

<script>
import LabelsInput from "./LabelsInput.vue"

export default {
  name: 'EditDynamicCategory',

  components: {
    LabelsInput
  },

  data: function() {
    return {
      sortTypeOptions: [
        {value: "title", text: "Title"},
        {value: "year", text: "Year"},
        {value: "date", text: "Date"}
      ],

      sortDirectionOptions: [
        {value: "asc", text: "Ascending"},
        {value: "desc", text: "Descending"}
      ],

      fields: [{key: "position", thStyle: "width: 80px"}, "title", "type", "plan", {key: "publish_status", label: "Status"}],

      items: [],

      debouncedPostJSON: null,

      fixedPositionToUnset: -1,
      fixedItemToUnset: null,
    };
  },
  props: {
    subject: String,
    language: String,
    parent: String,
    format: String,
    type: String,
    assetFormat: String
  },

  computed: {
    editor() {
      return this.$store.state.media.edits[this.subject] || {category_filter: {}}
    },

    mediaEdit: {
      get() {
        return this.editor.edit
      },

      set(value) {
        this.$store.commit('media/putEditItem', value)
      }
    },

    categoryFilterLabels: {
      get() {
        return this.mediaEdit.category_filter.labels
      },

      set(value) {
        this.updateCategoryFilter('labels', value)
      }
    },

    categoryFilterArtists: {
      get() {
        return this.mediaEdit.category_filter.artists
      },

      set(value) {
        this.updateCategoryFilter('artists', value)
      }
    },

    categoryFilterGenres: {
      get() {
        return this.mediaEdit.category_filter.genres
      },

      set(value) {
        this.updateCategoryFilter('genres', value)
      }
    },

    categoryFilterType: {
      get() {
        const allTypes = this.type == 'category' ? this.$helpers.getContentTopLevelTypes() : this.assetFormat == 'audio' ? this.$helpers.getAudioPlaylistTypes() : this.$helpers.getVideoPlaylistTypes() 
        let types = this.$_.clone(this.mediaEdit.category_filter.type).sort()

        if(JSON.stringify(allTypes) == JSON.stringify(types)) {
          types.splice(0, 0, '')
        }
        return types
      },

      set(value) {
        const allTypes = this.type == 'category' ? this.$helpers.getContentTopLevelTypes() : this.assetFormat == 'audio' ? this.$helpers.getAudioPlaylistTypes() : this.$helpers.getVideoPlaylistTypes() 
        let types = this.$_.clone(value).sort()

        if(JSON.stringify(allTypes) == JSON.stringify(types)) {
          types = []
        }

        else if(this.$_.includes(types, '')) {
          this.$_.pull(types, '')

          if(JSON.stringify(allTypes) != JSON.stringify(types) && JSON.stringify(this.mediaEdit.category_filter.type) != JSON.stringify(allTypes)) {
            types = this.$_.clone(allTypes)
            types.splice(0, 0, '')
          }
        }

        this.updateCategoryFilter('type', types)
      }
    },

    categoryFilterPlan: {
      get() {
        return this.mediaEdit.category_filter.plan
      },

      set(value) {
        this.updateCategoryFilter('plan', value)
      }
    },

    categoryFiltersLivestream: {
      get() {
        return this.mediaEdit.category_filter.options.includes('is_livestream')
      },

      set(value) {
        let options = this.mediaEdit.category_filter.options

        if(value && !options.includes('is_livestream')) {
          options.push('is_livestream')
          this.updateCategoryFilter('options', options)
        }

        else if(!value && options.includes('is_livestream')) {
          this.updateCategoryFilter('options', options.filter(o => o != 'is_livestream'))
        }
      }
    },

    categoryFiltersQualityStamp: {
      get() {
        return this.mediaEdit.category_filter.options.includes('quality_stamp')
      },

      set(value) {
        let options = this.mediaEdit.category_filter.options

        if(value && !options.includes('quality_stamp')) {
          options.push('quality_stamp')
          this.updateCategoryFilter('options', options)
        }

        else if(!value && options.includes('quality_stamp')) {
          this.updateCategoryFilter('options', options.filter(o => o != 'quality_stamp'))
        }
      }
    },

    categoryFiltersRecentlyAdded: {
      get() {
        return this.mediaEdit.category_filter.options.includes('recently_added')
      },

      set(value) {
        let options = this.mediaEdit.category_filter.options

        if(value && !options.includes('recently_added')) {
          options.push('recently_added')
          this.updateCategoryFilter('options', options)
        }

        else if(!value && options.includes('recently_added')) {
          this.updateCategoryFilter('options', options.filter(o => o != 'recently_added'))
        }
      }
    },

    categoryFiltersShoppingEnabled: {
      get() {
        return this.mediaEdit.category_filter.options.includes('shopping_enabled')
      },

      set(value) {
        let options = this.mediaEdit.category_filter.options

        if(value && !options.includes('shopping_enabled')) {
          options.push('shopping_enabled')
          this.updateCategoryFilter('options', options)
        }

        else if(!value && options.includes('shopping_enabled')) {
          this.updateCategoryFilter('options', options.filter(o => o != 'shopping_enabled'))
        }
      }
    },

    categoryFilterSortType: {
      get() {
        return this.mediaEdit.category_filter.sort_type
      },

      set(value) {
        this.updateCategoryFilter('sort_type', value)
      }
    },

    categoryFilterSortDirection: {
      get() {
        return this.mediaEdit.category_filter.sort_direction
      },

      set(value) {
        this.updateCategoryFilter('sort_direction', value)
      }
    },

    categoryFilterFixedItems: {
      get() {
        return this.mediaEdit.category_filter.fixed_items
      },

      set(value) {
        this.updateCategoryFilter('fixed_items', value)
      }
    },

    categoryFilterRotateSteps: {
      get() {
        return this.mediaEdit.category_filter.rotate_steps
      },

      set(value) {
        this.updateCategoryFilter('rotate_steps', parseInt(value))
      }
    },

    categoryFilterRotateInterval: {
      get() {
        return this.mediaEdit.category_filter.rotate_interval
      },

      set(value) {
        this.updateCategoryFilter('rotate_interval', parseInt(value))
      }
    },

  },

  watch: {
    assetFormat(value) {
      this.setAssetFormat(value)
    }
  },

  methods: {
    setAssetFormat(format) {
      if(format == 'audio') {
        let types = this.$_.clone(this.$helpers.getAudioPlaylistTypes())
        types.splice(0, 0, '')
        this.categoryFilterType = types
      } else if(format == 'video') {
        let types = this.$_.clone(this.$helpers.getVideoPlaylistTypes())
        types.splice(0, 0, '')
        this.categoryFilterType = types
      } else {
        this.categoryFilterType = this.mediaEdit ? this.mediaEdit.category_filter.type : []
      }
    },
    
    publishClass(status) {
      return {
        'bg-live': status == 'live',
        'bg-incomplete': status == 'incomplete',
        'bg-complete': status == 'complete',
        'bg-overdue': status == 'overdue',
        'bg-upcoming': status == 'upcoming',
        'bg-done': status == 'done',
        'bg-invalid': status == 'invalid'
        }
    },

    updateCategoryFilter(field, value) {
      let cf = this.mediaEdit.category_filter, update = {}
      cf[field] = value
      update[this.subject] = {category_filter: cf}
      this.$store.commit('media/updateEditItem', update)
      this.loadContent()
    },

    saveEditLabels(labels) {
      this.categoryFilterLabels = labels
    },

    saveEditArtists(artists) {
      this.categoryFilterArtists = artists
    },

    saveEditGenres(genres) {
      this.categoryFilterGenres = genres
    },

    loadContent() {
      let payload = JSON.stringify({
        labels: this.categoryFilterLabels,
        artists: this.categoryFilterArtists,
        genres: this.categoryFilterGenres,
        type: this.categoryFilterType,
        plan: this.categoryFilterPlan,
        options: this.mediaEdit.category_filter.options,
        sort_type: this.categoryFilterSortType,
        sort_direction: this.categoryFilterSortDirection,
        fixed_items: this.categoryFilterFixedItems,
        rotate_steps: Number(this.categoryFilterRotateSteps),
        rotate_interval: Number(this.categoryFilterRotateInterval)
      })

      fetch(
        `/admin/api/v2/media/dynamic-content?language=${this.language}${this.parent ? '&parent=' + this.parent : ''}${this.subject ? '&subject=' + this.subject : ''}&status=draft,ready,published`,
        {method: 'POST', body: payload}
      )
      .then(this.$helpers.JSONHandler)
      .then(resp => {
        this.items = this.processItems(resp.data.items)
        let cf = this.mediaEdit.category_filter, update = {}
        cf.fixed_items = resp.data.filter.fixed_items
        update[this.subject] = {category_filter: cf}
        this.$store.commit('media/updateEditItem', update)
      })
      .catch(error => {
        this.$root.app.showAlert(`Error retrieving dynamic category content: ${error.text}`)
      })
    },

    processItems(items) {
      return items.map(item => {
        item._actionsVisible = false
        if(item.meta) item.title = item.meta.title
        if(this.isFixedItem(item)) {
          item["_rowVariant"] = "dark"
          item.isFixed = true
        }
        else {
          item["_rowVariant"] = ""
          item.isFixed = false
        }
        return item;
      })
    },

    isFixedItem(item) {
      return this.$_.some(this.categoryFilterFixedItems, {id: item.id})
    },

    handleFixedPosition(item) {
      item._actionsVisible = !item._actionsVisible
    },

    unsetFixedPosition(item) {
      this.categoryFilterFixedItems = this.categoryFilterFixedItems.filter(fi => fi.id != item.id)
      this.loadContent()
    },

    getFixedPosition(item) {
      for (const ndx in this.categoryFilterFixedItems) {
        const fi = this.categoryFilterFixedItems[ndx]
        if(item.id == fi.id) {
          return fi.index + 1
        }
      }

      return null
    },

    updateFixedPosition(item) {
      let fixedItems =
        this.categoryFilterFixedItems.filter(fi => item.id != fi.id)

      const index = item._currentIndex >= 0 ? item._currentIndex : this.items.indexOf(item)
      fixedItems.unshift({index: index , id: item.id})

      this.categoryFilterFixedItems = fixedItems
      item._actionsVisible = false
      this.loadContent()
    },

    setCurrentIndex(index, item) {
      index = Number(index) - 1
      item._currentIndex = index
      this.items = this.items.map(it => {
        if(it.id == item.id) return item
        else return it
      })
    }
  },

  created() {
    this.setAssetFormat(this.assetFormat)
    this.loadContent()
  }

}
</script>

<style scoped>
.category-edit-form {
  margin-top: 2rem;
}
</style>
