var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-object-id": _vm.media.id } },
    [
      _c(
        "div",
        {
          staticClass:
            "tree-node shadow-sm d-flex align-items-center justify-content-between",
          class: { highlight: _vm.highlighted },
          on: { click: _vm.selectNode },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "tree-node-left flex-grow-1 d-flex align-items-center",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "shadow-sm grip mr-2 d-flex align-items-center justify-content-center",
                  class: _vm.publishStatusClass,
                },
                [
                  _vm.$store.state.media.searchMode ||
                  _vm.media.category_type == "root" ||
                  (_vm.$helpers.isTopLevel(_vm.media.type) &&
                    _vm.media.type != "category")
                    ? _c("MediaIcon", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        attrs: {
                          title: _vm.$helpers.prettify(_vm.media.type),
                          size: 15,
                          type: _vm.media.type,
                        },
                      })
                    : _c("font-awesome-icon", {
                        attrs: { icon: "grip-vertical" },
                      }),
                ],
                1
              ),
              !(
                _vm.media.type == "category" &&
                _vm.$store.state.media.searchMode
              )
                ? [
                    _vm.$helpers.hasChildren(_vm.media.type)
                      ? _c(
                          "div",
                          {
                            staticClass: "pointer",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.foldOut.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("Icon", {
                              attrs: { size: 24, icon: _vm.foldIcon },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _c("div", { staticStyle: { "min-width": "24px" } }),
              _c(
                "div",
                { staticClass: "mx-2" },
                [
                  _vm.nodeImageUrl
                    ? _c("b-img-lazy", {
                        attrs: { fluid: "", src: _vm.nodeImageUrl },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { staticClass: "ml-4" }, [
                _vm.showSerialNr(_vm.media)
                  ? _c("span", [_vm._v(_vm._s(_vm.media.serial_nr) + " ")])
                  : _vm._e(),
                _vm.media.type == "category" &&
                !_vm.$store.state.media.searchMode
                  ? _c("span", [
                      _vm._v(
                        _vm._s(`${_vm.media.meta.title} (${_vm.childrenCount})`)
                      ),
                    ])
                  : _c("span", [_vm._v(_vm._s(_vm.media.meta.title))]),
              ]),
              _c(
                "div",
                {
                  staticClass: "ml-2 font-weight-bold text-light",
                  staticStyle: { "font-size": "0.8em" },
                },
                [
                  _vm.media.meta.secondary_title
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.media.meta.secondary_title) + " "),
                      ])
                    : _vm.media.artists && _vm.media.artists.length > 0
                    ? _c("span", [_vm._v(_vm._s(_vm.media.artists.join(", ")))])
                    : _vm._e(),
                ]
              ),
              _vm.media.duplicate_of != null
                ? [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "ml-3",
                        attrs: {
                          title: `This item is a duplicate of ${_vm.media.duplicate_of}.`,
                        },
                      },
                      [
                        _c(
                          "b-badge",
                          { attrs: { variant: "warning" } },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "clone" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.media.type == "category" && _vm.$store.state.media.searchMode
                ? [
                    _c("span", { staticClass: "text-light" }, [
                      _vm._v(_vm._s(_vm.media.parent_crumbs)),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass:
                "tree-node-right d-flex justify-content-between mr-3",
            },
            [
              _vm.$helpers.isTopLevel(_vm.media.type)
                ? _c(
                    "div",
                    {
                      staticClass: "text-light",
                      staticStyle: { "margin-top": "6px" },
                    },
                    [_vm._v(" " + _vm._s(_vm.partner) + " ")]
                  )
                : _vm._e(),
              _vm.$helpers.isTopLevel(_vm.media.type) &&
              _vm.media.type != "category" &&
              _vm.partner
                ? _c(
                    "div",
                    {
                      staticClass: "mx-1",
                      staticStyle: { "margin-top": "5px" },
                    },
                    [_c("Icon", { attrs: { icon: "Scheiding", size: 24 } })],
                    1
                  )
                : _vm._e(),
              _vm.$helpers.isTopLevel(_vm.media.type) ||
              _vm.media.type == "episode" ||
              _vm.media.type == "issue" ||
              _vm.media.type == "audio" ||
              _vm.media.type == "album_track" ||
              _vm.media.type == "podcast_episode"
                ? [
                    _vm.media.type != "category"
                      ? _c(
                          "span",
                          { staticStyle: { "margin-top": "5px" } },
                          [
                            _c("b-badge", [
                              _vm._v(
                                _vm._s(_vm.$helpers.prettify(_vm.media.plan))
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.media.type != "category"
                      ? [
                          _vm.media["asset_format"]
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-left": "0.75rem",
                                    "margin-top": "5px",
                                  },
                                },
                                [
                                  _c("b-badge", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.media.asset_format.startsWith(
                                          "external"
                                        )
                                          ? "external"
                                          : "internal"
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "labels ml-1",
                        staticStyle: { "margin-top": "6px" },
                      },
                      [
                        _vm.media.category_type == "dynamic"
                          ? [
                              _vm.$_.isEqual(
                                _vm.media.category_filter.type,
                                _vm.$helpers.getContentTopLevelTypes()
                              )
                                ? [
                                    _c(
                                      "span",
                                      { staticClass: "mx-1" },
                                      [
                                        _c(
                                          "b-badge",
                                          { attrs: { variant: "secondary" } },
                                          [_vm._v(" All Types ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._l(
                                    _vm.media.category_filter.type,
                                    function (k) {
                                      return _c(
                                        "span",
                                        { key: k, staticClass: "mx-1" },
                                        [
                                          _c(
                                            "b-badge",
                                            { attrs: { variant: "secondary" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$helpers.prettify(k)
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                            ]
                          : _vm._e(),
                        _vm.media.category_type == "manual"
                          ? _c(
                              "span",
                              { staticClass: "ml-1" },
                              [_c("b-badge", [_vm._v("manual")])],
                              1
                            )
                          : _vm._e(),
                        _vm.media.type != "category" ||
                        _vm.media.category_type == "dynamic"
                          ? [
                              _c(
                                "span",
                                {
                                  staticClass: "ml-2",
                                  attrs: { id: "labels-more-" + _vm.media.id },
                                },
                                [
                                  _c(
                                    "b-badge",
                                    {
                                      staticClass: "py-1",
                                      class: {
                                        "badge-dark":
                                          _vm.labelsAggregate.length == 0,
                                      },
                                      attrs: {
                                        variant:
                                          _vm.labelsAggregate.length > 0
                                            ? "secondary"
                                            : "dark",
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "filter" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    placement: "right",
                                    target: "labels-more-" + _vm.media.id,
                                    triggers: "hover focus",
                                  },
                                },
                                _vm._l(_vm.labelsAggregate, function (k) {
                                  return _c(
                                    "div",
                                    { key: k },
                                    [
                                      _c(
                                        "b-badge",
                                        { attrs: { variant: "secondary" } },
                                        [_vm._v(" " + _vm._s(k) + " ")]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                : _vm._e(),
              !(
                _vm.media.type == "season" ||
                _vm.media.type == "year" ||
                _vm.media.type == "product" ||
                _vm.media.type == "product_category"
              )
                ? _c(
                    "div",
                    {
                      staticClass: "mx-1",
                      staticStyle: { "margin-top": "5px" },
                    },
                    [_c("Icon", { attrs: { icon: "Scheiding", size: 24 } })],
                    1
                  )
                : _vm._e(),
              _vm.actionsState == "reordering"
                ? _c(
                    "b-button-group",
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          attrs: {
                            title: "Save Reordering",
                            variant: "primary",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.saveReordering.apply(null, arguments)
                            },
                          },
                        },
                        [_c("Icon", { attrs: { icon: "Save" } })],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          attrs: {
                            title: "Restore original order",
                            variant: "primary",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.restoreReordering.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "undo-alt" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.actionsState == "default"
                ? [
                    !_vm.$helpers.isTopLevel(_vm.media.type) ||
                    (_vm.media.type == "category" && _vm.media.allow_archiving)
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "mx-1",
                            attrs: { title: "Archive", variant: "primary" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                _vm.archiveModalVisible = true
                              },
                            },
                          },
                          [_c("Icon", { attrs: { icon: "Archive" } })],
                          1
                        )
                      : _vm.media.allow_archiving == false
                      ? _c(
                          "b-button",
                          {
                            staticClass: "mx-1",
                            attrs: { disabled: "", variant: "secondary" },
                          },
                          [_c("Icon", { attrs: { icon: "Archive" } })],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "mx-1",
                        attrs: {
                          disabled: !(
                            _vm.userType == "admin" ||
                            _vm.userType == "editor_in_chief"
                          ),
                          title: _vm.isPublished
                            ? "Unpublish item"
                            : "Publish item",
                          variant: _vm.isPublished ? "danger" : "primary",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.publish.apply(null, arguments)
                          },
                        },
                      },
                      [_c("Icon", { attrs: { icon: "globe" } })],
                      1
                    ),
                    _vm.childMediaTypes.length > 1
                      ? [
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "dropdown mx-1",
                              attrs: { variant: "primary" },
                            },
                            [
                              _c(
                                "template",
                                { slot: "button-content" },
                                [_c("Icon", { attrs: { icon: "Add" } })],
                                1
                              ),
                              _vm._l(_vm.childMediaTypes, function (childType) {
                                return _c(
                                  "b-dropdown-item",
                                  {
                                    key: childType,
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.addChild(childType)
                                      },
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$helpers.prettify(childType))
                                      ),
                                    ]),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ]
                      : _vm.childMediaTypes.length == 1 &&
                        _vm.media.type != "photo_package"
                      ? [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true },
                                },
                              ],
                              staticClass: "mx-1",
                              attrs: {
                                title:
                                  "Add " +
                                  _vm.$helpers.prettify(_vm.childMediaTypes[0]),
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.addChild(_vm.childMediaTypes[0])
                                },
                              },
                            },
                            [_c("Icon", { attrs: { icon: "Add" } })],
                            1
                          ),
                        ]
                      : [
                          _c(
                            "b-button",
                            {
                              staticClass: "mx-1",
                              attrs: { disabled: "", variant: "secondary" },
                            },
                            [_c("Icon", { attrs: { icon: "Add" } })],
                            1
                          ),
                        ],
                    _vm.$helpers.isPlayable(_vm.media.type) &&
                    !_vm.media.asset_format.startsWith("external") &&
                    (_vm.userType == "admin" ||
                      _vm.userType == "editor_in_chief")
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "mx-1",
                            attrs: {
                              id: `download-asset-${_vm.media.id}`,
                              title: "Download primary asset",
                              variant: "primary",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.downloadPrimaryAsset(_vm.media.id)
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "download" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    (_vm.$helpers.isTopLevel(_vm.media.type) ||
                      _vm.media.type == "category") &&
                    _vm.media.type != "external" &&
                    _vm.media.type != "photo_package"
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "mx-1",
                            attrs: {
                              title: "Preview",
                              href: _vm.getPreviewUrl(_vm.media),
                              target: "_blank",
                              rel: "noopener noreferrer",
                              variant: "primary",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { "padding-top": "2px" } },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "play-circle" },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _c(
                          "b-button",
                          {
                            staticClass: "mx-1",
                            attrs: { disabled: "", variant: "secondary" },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { "padding-top": "2px" } },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "play-circle" },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                    _vm.$helpers.hasDetailPage(_vm.media.type)
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "mx-1",
                            attrs: {
                              title: "Edit Embedded Dashboard",
                              variant: "primary",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                _vm.dashboardVisible
                                  ? _vm.closeDashboard()
                                  : _vm.showDashboard()
                              },
                            },
                          },
                          [
                            _c("Icon", {
                              attrs: { icon: "Embedded_dashboard" },
                            }),
                          ],
                          1
                        )
                      : _vm.media.type != "category"
                      ? _c(
                          "b-button",
                          {
                            staticClass: "mx-1",
                            attrs: { disabled: "", variant: "secondary" },
                          },
                          [
                            _c("Icon", {
                              attrs: { icon: "Embedded_dashboard" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.media.type == "artist_page"
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "mx-1",
                            attrs: {
                              title: "Edit Embedded Playlists",
                              variant: "primary",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                _vm.playlistsVisible
                                  ? _vm.closePlaylists()
                                  : _vm.showPlaylists()
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "list-ol" },
                            }),
                          ],
                          1
                        )
                      : _vm.media.type != "category"
                      ? _c(
                          "b-button",
                          {
                            staticClass: "mx-1",
                            attrs: { disabled: "", variant: "secondary" },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "list-ol" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.media.type != "photo_package"
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "mx-1",
                            attrs: { title: "Edit", variant: "primary" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                _vm.editorVisible
                                  ? _vm.closeEditor()
                                  : _vm.showEditor()
                              },
                            },
                          },
                          [_c("Icon", { attrs: { icon: "edit" } })],
                          1
                        )
                      : _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "mx-1",
                            attrs: {
                              title: _vm.media.id,
                              disabled: "",
                              variant: "secondary",
                            },
                          },
                          [_c("Icon", { attrs: { icon: "edit" } })],
                          1
                        ),
                    _c(
                      "b-popover",
                      {
                        attrs: {
                          target: `download-asset-${_vm.media.id}`,
                          triggers: "click blur",
                          placement: "bottom",
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "bright-download-url",
                            attrs: { href: _vm.downloadUrl, target: "_blank" },
                          },
                          [_vm._v("Download file...")]
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm.editorVisible
        ? _c(
            "div",
            [
              _c("EditMedia", {
                attrs: { "media-id": _vm.media.id },
                on: {
                  close: _vm.closeEditor,
                  save: function ($event) {
                    return _vm.$emit("save", $event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.dashboardVisible
        ? _c(
            "div",
            [
              _c("EmbeddedDashboard", {
                attrs: { item: _vm.mediaExpanded },
                on: {
                  save: function ($event) {
                    return _vm.$emit("save", $event)
                  },
                  close: _vm.closeDashboard,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.playlistsVisible
        ? _c(
            "div",
            [
              _c("EmbeddedPlaylists", {
                attrs: { item: _vm.mediaExpanded },
                on: {
                  save: function ($event) {
                    return _vm.$emit("save", $event)
                  },
                  close: _vm.closePlaylists,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.childrenVisible
        ? _c(
            "draggable",
            {
              staticClass: "collapse-children",
              attrs: { disabled: _vm.draggingDisabled, handle: ".grip" },
              on: { update: _vm.updatedOrder },
              model: {
                value: _vm.mediaExpanded.children,
                callback: function ($$v) {
                  _vm.$set(_vm.mediaExpanded, "children", $$v)
                },
                expression: "mediaExpanded.children",
              },
            },
            [
              _vm.mediaExpanded && _vm.mediaExpanded.children.length == 0
                ? [
                    _c(
                      "p",
                      {
                        staticClass: "text-center mt-2",
                        staticStyle: { color: "#9FA9AE" },
                      },
                      [_vm._v("No subitems")]
                    ),
                  ]
                : _vm._l(_vm.childItems, function (m) {
                    return _c("TreeNode", {
                      key: m.id,
                      ref: m.id,
                      refInFor: true,
                      attrs: { media: m },
                      on: {
                        "node-selected": _vm.selectChildNode,
                        "editor-visible": _vm.handleChildEdit,
                        save: function ($event) {
                          return _vm.$emit("save", $event)
                        },
                      },
                    })
                  }),
            ],
            2
          )
        : _vm._e(),
      _c("SimpleModal", {
        attrs: {
          value: _vm.closeModalVisible,
          msg: "There are unsaved edits. Are you sure you want to close the editor and discard all changes?",
        },
        on: {
          ok: _vm.doCloseEditor,
          cancel: function ($event) {
            _vm.closeModalVisible = false
          },
        },
      }),
      _c("SimpleModal", {
        attrs: {
          value: _vm.archiveModalVisible,
          msg: "Are you sure you want to archive this item?",
        },
        on: {
          ok: _vm.doArchive,
          cancel: function ($event) {
            _vm.archiveModalVisible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }