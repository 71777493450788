var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "users" },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-end align-items-center my-4",
          attrs: { id: "user-count-stats" },
        },
        [
          _c(
            "b-button",
            { attrs: { variant: "primary" }, on: { click: _vm.exportUsers } },
            [
              _c("Icon", { attrs: { icon: "export" } }),
              _c("span", { staticClass: "ml-2" }, [_vm._v("Export Users")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("b-table", {
            attrs: {
              hover: "",
              striped: "",
              items: _vm.items,
              fields: _vm.fields,
              "per-page": _vm.perPage,
              "current-page": _vm.currentPage,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(edit_user)",
                fn: function (row) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-center" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-2",
                            attrs: { size: "sm", variant: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.setupEditor(row)
                              },
                            },
                          },
                          [_c("Icon", { attrs: { icon: "edit" } })],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "row-details",
                fn: function (row) {
                  return [
                    _c("b-container", [
                      _c(
                        "form",
                        { attrs: { autocomplete: "off" } },
                        [
                          _c("input", {
                            staticStyle: { display: "none" },
                            attrs: {
                              autocomplete: "false",
                              name: "hidden",
                              type: "text",
                            },
                          }),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "ID",
                                "label-for": "id-input",
                                horizontal: "",
                                "label-cols": 2,
                              },
                            },
                            [
                              _c("id-input", {
                                attrs: { id: "id-input", value: row.item.id },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Username",
                                "label-for": "username-input",
                                horizontal: "",
                                "label-cols": 2,
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "username-input",
                                  value: row.item.username,
                                  state: _vm.usernameState,
                                  role: "presentation",
                                  autocomplete: "off",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.checkUsername($event, row.item)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Country",
                                "label-for": "users-select-geo",
                                horizontal: "",
                                "label-cols": 2,
                              },
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "users-select-geo",
                                  options: _vm.geoOptions,
                                },
                                model: {
                                  value: row.item.country,
                                  callback: function ($$v) {
                                    _vm.$set(row.item, "country", $$v)
                                  },
                                  expression: "row.item.country",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.$config.hasAdultCheck
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Is Adult",
                                    "label-for": "is-adult",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c("b-form-checkbox", {
                                    attrs: { switch: "" },
                                    model: {
                                      value: row.item.is_adult,
                                      callback: function ($$v) {
                                        _vm.$set(row.item, "is_adult", $$v)
                                      },
                                      expression: "row.item.is_adult",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          row.item.type == "partner"
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Partner",
                                    "label-for": "users-select-partner",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      id: "users-select-partner",
                                      options: _vm.partnerOptions,
                                    },
                                    model: {
                                      value: row.item.partner,
                                      callback: function ($$v) {
                                        _vm.$set(row.item, "partner", $$v)
                                      },
                                      expression: "row.item.partner",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          row.item.type == "partner"
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Allow Dashboard login",
                                    "label-for": "users-allow-dashboard",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c("b-form-checkbox", {
                                    attrs: { id: "users-allow-dashboard" },
                                    model: {
                                      value: _vm.allowPartnerDashboard,
                                      callback: function ($$v) {
                                        _vm.allowPartnerDashboard = $$v
                                      },
                                      expression: "allowPartnerDashboard",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Mailing List Subscriptions",
                                "label-for": "users-list-subscriptions",
                                horizontal: "",
                                "label-cols": 2,
                              },
                            },
                            [
                              _c("UserListSubscriptions", {
                                attrs: {
                                  id: "users-list-subscriptions",
                                  user: row.item,
                                },
                                model: {
                                  value: row.item.list_subscriptions,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      row.item,
                                      "list_subscriptions",
                                      $$v
                                    )
                                  },
                                  expression: "row.item.list_subscriptions",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Actions",
                                "label-for": "actions-bar",
                                horizontal: "",
                                "label-cols": 2,
                              },
                            },
                            [
                              _c(
                                "b-button-group",
                                { attrs: { id: "actions-bar" } },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveModifications(row.item)
                                        },
                                      },
                                    },
                                    [
                                      _c("Icon", { attrs: { icon: "Save" } }),
                                      _c("span", { staticClass: "ml-2" }, [
                                        _vm._v("Save Modifications"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.sendPasswordResetMail(
                                            row.item.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "at" },
                                      }),
                                      _c("span", [
                                        _vm._v(" Send Password reset mail"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  row.item.type != "customer" &&
                                  _vm.$config.use2FA
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            id: "2fa-info-button",
                                            variant: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.send2FAInfo(
                                                row.item.username
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "at" },
                                          }),
                                          _vm._v(" Send 2FA info mail "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  row.item.status != "locked"
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "danger" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.lockUser(row.item.id)
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "ban" },
                                          }),
                                          _c("span", [_vm._v(" Lock Account")]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  row.item.status != "subscribed" &&
                                  row.item.subscription_offer != null
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.reactivateUser(
                                                row.item.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "door-open" },
                                          }),
                                          _c("span", [_vm._v(" Reactivate")]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showWatchedItems(
                                            row.item.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "search" },
                                      }),
                                      _c("span", [
                                        _vm._v(" Show Watched Items"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showOrders(row.item.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "search" },
                                      }),
                                      _c("span", [
                                        _vm._v(" Show Order Overview"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.perPage
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-center" },
            [
              _c("b-pagination", {
                attrs: {
                  "total-rows": _vm.items.length,
                  "per-page": _vm.perPage,
                },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("AddUser", { ref: "addUser" }),
      _c("CustomerOrders", { ref: "customerOrders" }),
      _c(
        "b-modal",
        {
          attrs: { "hide-footer": "", size: "lg" },
          model: {
            value: _vm.showContentModal,
            callback: function ($$v) {
              _vm.showContentModal = $$v
            },
            expression: "showContentModal",
          },
        },
        [_c("WatchedItemsTable", { attrs: { url: _vm.watchedItemsUrl } })],
        1
      ),
      _c("iframe", {
        staticStyle: { display: "none" },
        attrs: { id: "export-users-frame" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }